<template>
  <div
    style="height: inherit"
    class="user-home"
    :class="{ 'responsive-web-user': responsiveSize }"
  >
    <location-bar v-if="$store.getters['app/windowSize'] <= 767" />
    <div
      class="wrap-top-image-section d-flex justify-content-center align-items-center"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/app-images/home-banner-image.webp') + ')',
      }"
      style="background-size: cover"
    >
      <b-col
        md="8"
        class="pl-5 pr-col-mobile"
      >
        <h4
          v-if="responsiveSize"
          class="mb-2 text-white font-weight-normal sub-title-text-home"
        >
          {{ $t('What are you looking for today?') }}
          <div class="d-flex align-items-center">
            <span style="margin-top: 8px">{{ $t('in') }}</span>
            <model-select
              v-model="selectedCountry"
              class="d-inline select-city-options"
              :options="
                userCountry.map(x => ({
                  text: x.country_name,
                  value: x.id,
                }))
              "
            />
            <model-select
              ref="selectedCity"
              v-model="selectedCity"
              class="d-inline select-city-options"
              :options="
                userCities.map(x => ({
                  text: x.city_name,
                  value: x.id,
                }))
              "
            />
          </div>
        </h4>
        <h4
          v-if="!responsiveSize"
          class="mb-2 text-white font-weight-normal sub-title-text-home d-flex"
        >
          <p class="w-100">
            {{ $t('What are you looking for today?') }} {{ $t('in') }}
          </p>
          <model-select
            v-model="selectedCountry"
            class="d-inline select-city-options"
            :options="
              userCountry.map(x => ({
                text: x.country_name,
                value: x.id,
              }))
            "
          />
          <model-select
            ref="selectedCity"
            v-model="selectedCity"
            class="d-inline select-city-options"
            :options="
              userCities.map(x => ({
                text: x.city_name,
                value: x.id,
              }))
            "
          />
        </h4>
        <b-input-group class="btn-input-group-search">
          <b-form-input
            v-model="searchCriteria"
            class="search-criteria-input"
            placeholder="Food, Grocery, Flower, Salon, Doctor, Pet Supplies"
            @keyup="onSearch"
            @blur="hideAutoComplete"
          />
          <b-input-group-append>
            <b-button
              variant="outline-primary"
              class="btn-submit-request"
            >
              {{ $t('GO') }}
            </b-button>
          </b-input-group-append>
          <div
            v-if="showAutoComplete"
            class="wrap-auto-suggest-box"
            :class="appAutoCompleteLoading ? 'app-autocomplete-loading' : null"
          >
            <app-loading v-if="appAutoCompleteLoading" />
            <ul
              v-if="!appAutoCompleteLoading && autoCompleteData.length > 0"
              class="general-search mt-1"
            >
              <li
                v-for="(item, index) of autoCompleteData"
                :key="index"
                @click="onOptionClick(item)"
              >
                <div class="wrap-item-info-autocomplete">
                  <b-avatar
                    ref="previewEl"
                    :src="$helpers.imageHelper(item.picture)"
                    size="50px"
                    rounded
                  />
                  {{ item.name }}
                </div>
              </li>
            </ul>
            <p
              v-if="!appAutoCompleteLoading && autoCompleteData.length === 0"
              class="text-center pt-1"
            >
              {{ $t('No data found') }}...
            </p>
          </div>
        </b-input-group>
      </b-col>
      <b-col
        md="4"
        class="d-none-mobile"
      />
    </div>
    <div
      v-if="sliderCategory && sliderCategory.length > 0"
      class="wrap-home-page-title"
    >
      <h2 class="m-0 p-2 text-black pb-0-important">
        {{ $t('Categories') }}
      </h2>
    </div>
    <no-data-available
      v-if="
        loadingOptions.menuLoaded &&
          sliderCategory.length === 0 &&
          userMenuData.length === 0 &&
          loadingOptions.offersLoaded &&
          offerMenus.length === 0 &&
          userOffers.length === 0 &&
          featuredMenus.length === 0
      "
      :type="'product'"
    />
    <row-box-skeleton
      v-if="!loadingOptions.menuLoaded"
      :show-top-line="false"
      class="px-2 mt-1"
    />
    <swiper
      v-if="loadingOptions.menuLoaded && !responsiveSize && sliderCategory.length > 0"
      class="swiper-progress"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(items, index) in sliderCategory"
        :key="index"
      >
        <section class="grid-view">
          <user-offer-cards
            v-for="(product, counter) in items"
            :key="counter"
            class="menu-category-offer-cards"
            :card-type="'menu'"
            :product="product"
          />
        </section>
      </swiper-slide>
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
    <div
      v-if="loadingOptions.menuLoaded && responsiveSize && userMenuData.length > 0"
      class="wrapper-menu-options"
    >
      <user-offer-cards
        v-for="(product, counter) in userMenuData"
        :key="counter"
        class="menu-category-offer-cards"
        :card-type="'menu'"
        :product="product"
      />
    </div>
    <div
      v-if="offerMenus && offerMenus.length > 0"
      class="wrap-home-page-title"
    >
      <h2 class="m-0 p-2 text-black pb-0-important">
        {{ $t('Offers') }}
      </h2>
    </div>
    <row-box-skeleton
      v-if="!loadingOptions.offersLoaded"
      :show-top-line="false"
      class="px-2 mt-1"
    />
    <swiper
      v-if="loadingOptions.offersLoaded && !responsiveSize && offerMenus.length > 0"
      class="swiper-progress"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(items, index) in offerMenus"
        :key="index"
      >
        <section class="grid-view">
          <user-offer-cards
            v-for="(product, counter) in items"
            :key="counter"
            class="menu-category-offer-cards"
            :product="product"
          />
        </section>
      </swiper-slide>
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
    <div v-if="responsiveSize && userOffers && userOffers.length > 0">
      <section class="section-mobile-wrapper user-offers-card-section">
        <user-offer-cards
          v-for="(product, counter) in userOffers.filter(x => x.featured === 0)"
          :key="counter"
          class="menu-category-offer-cards"
          :product="product"
        />
      </section>
    </div>
    <div
      v-if="featuredMenus && featuredMenus.length > 0"
      class="wrap-home-page-title"
    >
      <h2 class="m-0 p-2 text-black pb-0-important">
        {{ $t('Featured') }}
      </h2>
    </div>
    <row-box-skeleton
      v-if="!loadingOptions.menuLoaded"
      :show-top-line="false"
      class="px-2 mt-1"
    />
    <swiper
      v-if="loadingOptions.menuLoaded && !responsiveSize && featuredMenus.length > 0"
      class="swiper-progress"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(items, index) in featuredMenus"
        :key="index"
      >
        <section class="grid-view">
          <user-offer-cards
            v-for="(product, counter) in items"
            :key="counter"
            class="menu-category-offer-cards"
            :product="product"
          />
        </section>
      </swiper-slide>
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
    <div v-if="responsiveSize && userOffers && userOffers.length > 0">
      <section class="section-mobile-wrapper user-offers-card-section">
        <user-offer-cards
          v-for="(product, counter) in userOffers.filter(x => x.featured === 1 || x.is_featured === 1)"
          :key="counter"
          class="menu-category-offer-cards"
          :product="product"
        />
      </section>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { ModelSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import AppLoading from '@/@core/layouts/components/app-navbar/components/AppLoading.vue'
import RowBoxSkeleton from '@/@core/layouts/shared/RowBoxSkeleton.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import LocationBar from '@/layouts/components/LocationBar.vue'
import { useUserUi } from './useUser'
import UserOfferCards from './shared/UserOfferCards.vue'
import NoDataAvailable from './shared/NoDataAvailable.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    ModelSelect,
    AppLoading,
    UserOfferCards,
    Swiper,
    SwiperSlide,
    RowBoxSkeleton,
    LocationBar,
    NoDataAvailable,
  },
  data() {
    return {
      userMenuData: [],
      lastCalledCity: '',
      userCountry: [],
      setActiveClass: 0,
      slugActive: '',
      menuCategories: [],
      searchCriteria: '',
      showAutoComplete: false,
      autoCompleteData: [],
      userCities: [],
      appAutoCompleteLoading: false,
      userOffers: [],
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      dividerCounter: 4,
      loadingOptions: {
        menuLoaded: false,
        offersLoaded: false,
      },
      selectedCity: null,
      ipInfo: null,
      selectedCountry: '',
    }
  },
  computed: {
    responsiveSize() {
      return this.$store.getters['app/windowSize'] <= 1024
    },
    filteredMenus() {
      // eslint-disable-next-line no-unused-vars
      return this.userMenuData.filter((x, i) => x.slug === this.slugActive)
    },
    offerMenus() {
      return this.splitArrayData(this.userOffers.filter(x => x.featured === 0))
    },
    featuredMenus() {
      const featuredData = this.userOffers.filter(x => x.featured === 1 || x.is_featured === 1)
      return this.splitArrayData(featuredData)
    },
    sliderCategory() {
      return this.splitArrayData(this.userMenuData)
    },
  },
  watch: {
    selectedCountry: {
      handler() {
        if (this.selectedCountry) {
          localStorage.setItem('userSelectedCountryId', this.selectedCountry)
          this.onCountryChange()
        }
      },
    },
    selectedCity: {
      handler() {
        if (this.selectedCity) {
          // eslint-disable-next-line eqeqeq
          if (this.lastCalledCity != this.selectedCity) {
            this.lastCalledCity = this.selectedCity
            this.updateCityInfo()
          }
        }
      },
    },
  },
  async mounted() {
    window.that = this
    if (!localStorage.getItem('userSelectedCountryId') || !localStorage.getItem('userSelectedCityId')) {
      try {
        const ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
        this.ipInfo = await ipInfo.json()
      } catch (error) {
        console.log(error)
      }
    }
    window.addEventListener('resize', this.changeDividerCounter)
    this.changeDividerCounter()
    document.getElementById('app').classList.add('no-padding-layout')
    this.getUserMenus()
    this.getPromocodes()
    this.countryList()
    eventBus.$on('location-searched', payload => {
      let queryString = ''
      if (!payload.isCustom && payload.geometry) {
        queryString = `latitude=${payload.geometry.location.lat()}&longitude=${payload.geometry.location.lng()}`
      } else if (payload.lat && payload.lng) {
        queryString = `latitude=${payload.lat}&longitude=${payload.lng}`
      }
      this.getUserMenus(queryString)
      this.getPromocodes(queryString)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.changeDividerCounter)
    eventBus.$off('location-searched')
    document.getElementById('app').classList.remove('no-padding-layout')
  },
  methods: {
    countryList() {
      const { menuCountryList } = useUserUi()
      menuCountryList()
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            this.userCountry = data.responseData
            let countrySet = false
            if (this.ipInfo && this.ipInfo.location && this.ipInfo.location.country) {
              const isCountryExist = this.userCountry.find(x => x.country_name === this.ipInfo.location.country.name)
              if (isCountryExist) {
                localStorage.setItem('userSelectedCountryId', isCountryExist.id)
                this.selectedCountry = isCountryExist.id
                console.log(`Country set: ${this.selectedCountry}`)
                countrySet = true
              }
            }
            if (countrySet) {
              this.onCountryChange()
              console.log('Country returned')
              return
            }
            if (window.SuperApp.getters.userCountry()) {
              this.selectedCountry = window.SuperApp.getters.userCountry()
            }
            if (!this.selectedCountry) {
              const userInfo = window.SuperApp.getters.userInfo()
              if (userInfo) {
                this.selectedCountry = userInfo.country_id
              }
            }
            if (!this.selectedCountry) {
              this.selectedCountry = this.userCountry[0].id
            }
            localStorage.setItem('userSelectedCountryId', this.selectedCountry)
            if (this.selectedCountry) {
              this.onCountryChange()
            }
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onCountryChange() {
      this.userCities = []
      this.selectedCity = null
      const { cityByCountry } = useUserUi()
      showLoader()
      cityByCountry(this.selectedCountry)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.userCities = data.responseData
            let citySet = false
            if (this.ipInfo && this.ipInfo.location) {
              const isCityExist = this.userCities.find(x => x.city_name === this.ipInfo.location.city)
              if (isCityExist) {
                localStorage.setItem('userSelectedCityId', isCityExist.id)
                this.selectedCity = isCityExist.id
                console.log(`City set: ${this.selectedCity}`)
                citySet = true
              }
            }
            if (citySet) {
              this.getUserMenus()
              this.getPromocodes()
              console.log('City returned')
              hideLoader()
              return
            }
            const cityId = window.SuperApp.getters.userCity()
            // eslint-disable-next-line eqeqeq
            if (cityId && this.userCities.find(x => x.id == cityId)) {
              this.selectedCity = cityId
            } else {
              this.selectedCity = this.userCities[0].id
              localStorage.setItem('userSelectedCityId', this.selectedCity)
            }
            // eslint-disable-next-line radix
            this.selectedCity = parseInt(this.selectedCity)
            this.getUserMenus()
            this.getPromocodes()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getPromocodes(queryString = '') {
      // eslint-disable-next-line eqeqeq
      if (this.lastSelectedPromoCity && this.lastSelectedPromoCity == this.selectedCity) {
        this.loadingOptions.offersLoaded = true
        return
      }
      const { promocodesMenu } = useUserUi()
      const params = []
      if (this.selectedCity) {
        this.lastSelectedPromoCity = this.selectedCity
        params.push(`city_id=${this.selectedCity}`)
      }
      if (queryString) {
        params.push(queryString)
      }
      promocodesMenu(`?${params.join('&')}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            data.responseData.forEach(element => {
              if (!this.userOffers.find(x => x.id === element.id)) {
                this.userOffers.push(element)
              }
            })
            window.userOffers = this.userOffers
          }
          this.loadingOptions.offersLoaded = true
        })
        .catch(error => {
          this.loadingOptions.offersLoaded = true
          showErrorNotification(this, error)
        })
    },
    getUserMenus(queryString = '') {
      // eslint-disable-next-line eqeqeq
      if (this.lastSelectedMenuCity && this.lastSelectedMenuCity == this.selectedCity) {
        this.loadingOptions.menuLoaded = true
        return
      }
      this.userOffers = []
      const { usersMenu } = useUserUi()
      const params = []
      if (this.selectedCity) {
        this.lastSelectedMenuCity = this.selectedCity
        params.push(`city_id=${this.selectedCity}`)
      }else{
        return 
      }
      if (queryString) {
        params.push(queryString)
      }
      usersMenu(`?${params.join('&')}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.userMenuData = data.responseData.services
            window.userMenuData = this.userMenuData
            this.menuCategories = this.userMenuData.map(x => ({
              title: x.title,
              slug: x.slug,
              image: x.featured_image,
            }))
            const featuredMenus = window.SuperApp.actions.cloneDeep(this.userMenuData.filter(x => x.is_featured === 1))
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < featuredMenus.length; i++) {
              if (!this.userOffers.find(x => x.id === featuredMenus[i].id)) {
                featuredMenus[i].isMenuCategory = true
                this.userOffers.unshift(featuredMenus[i])
              }
            }
            if (this.menuCategories[0]) {
              this.slugActive = this.menuCategories[0].slug
            }
          }
          this.loadingOptions.menuLoaded = true
        })
        .catch(error => {
          this.loadingOptions.menuLoaded = true
          showErrorNotification(this, error)
        })
    },
    updateCityInfo() {
      localStorage.setItem('userSelectedCityId', this.selectedCity)
      // eslint-disable-next-line eqeqeq
      if (this.userCities.find(x => x.id == this.selectedCity)) {
        // eslint-disable-next-line eqeqeq
        localStorage.setItem('userSelectedCityName', this.userCities.find(x => x.id == this.selectedCity).city_name)
      }
      const { updateUserCity } = useUserUi()
      const formData = new FormData()
      eventBus.$emit('userLocationChanged', window.SuperApp.actions.selectedCityName())
      formData.append('city_id', this.selectedCity)
      updateUserCity(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.getUserMenus()
            this.getPromocodes()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    changeDividerCounter() {
      if (window.innerWidth <= 767) {
        this.dividerCounter = 1
      } else {
        this.dividerCounter = 4
      }
    },
    splitArrayData(array) {
      const clonedArray = window.SuperApp.actions.cloneDeep(array)
      return new Array(Math.ceil(clonedArray.length / this.dividerCounter)).fill().map(() => clonedArray.splice(0, this.dividerCounter))
    },
    setActive(index, currentSlug) {
      this.setActiveClass = index
      this.slugActive = currentSlug
    },
    showNext() {
      if (this.setActiveClass >= this.userMenuData.length - 1) {
        this.setActiveClass = 0
      } else {
        this.setActiveClass += 1
      }
      this.slugActive = this.menuCategories[this.setActiveClass].slug
    },
    showPrev() {
      if (this.setActiveClass <= 0) {
        this.setActiveClass = this.userMenuData.length - 1
      } else {
        this.setActiveClass -= 1
      }
      this.slugActive = this.menuCategories[this.setActiveClass].slug
    },
    onSearch() {
      if (this.searchCriteria) {
        if (this.searchCriteria.length <= 1) {
          return
        }
        const { userStoreAutoComplete } = useUserUi()
        this.appAutoCompleteLoading = true
        userStoreAutoComplete({
          searchInput: this.searchCriteria,
        }).then(({ data }) => {
          if (data.statusCode === '200') {
            let autoCompleteOptions = []
            autoCompleteOptions = autoCompleteOptions.concat(this.transformData('menus', data.responseData.menus))
            autoCompleteOptions = autoCompleteOptions.concat(this.transformData('service_categories', data.responseData.service_categories))
            autoCompleteOptions = autoCompleteOptions.concat(this.transformData('service_subcategories', data.responseData.service_subcategories))
            autoCompleteOptions = autoCompleteOptions.concat(this.transformData('services', data.responseData.services))
            autoCompleteOptions = autoCompleteOptions.concat(this.transformData('store_categories', data.responseData.store_categories))
            autoCompleteOptions = autoCompleteOptions.concat(this.transformData('store_items', data.responseData.store_items))
            autoCompleteOptions = autoCompleteOptions.concat(this.transformData('stores', data.responseData.stores))
            this.autoCompleteData = autoCompleteOptions
            window.autoCompleteData = this.autoCompleteData
          }
          this.appAutoCompleteLoading = false
        })
        this.showAutoComplete = true
      } else {
        this.showAutoComplete = false
        this.autoCompleteData = []
      }
    },
    transformData(key, array) {
      return array.map(x => ({
        id: x.id,
        name: `${x.name} ${x.store && x.store.store_name ? `from ${x.store.store_name}` : ''}`,
        picture: x.picture,
        payload: x,
        key,
      }))
    },
    hideAutoComplete() {
      setTimeout(() => {
        // this.showAutoComplete = false
      }, 250)
    },
    onOptionClick(option) {
      this.searchCriteria = option.name
      this.showAutoComplete = false
      let redirectUrl = ''
      let { slug } = option.payload
      if (!slug && option.payload.store) {
        slug = option.payload.store
      }
      if (!slug && option.payload.service_category) {
        slug = option.payload.service_category
      }
      if (slug && slug.slug) slug = slug.slug
      switch (option.key) {
        case 'service_categories':
          redirectUrl = `/user/services/subcategories/list/${slug}`
          break
        case 'stores':
          redirectUrl = `/user/store/details/${slug}`
          break
        case 'store_categories':
          redirectUrl = `/user/store/list/${slug}`
          break
        case 'store_items':
          redirectUrl = `/user/store/details/${slug}`
          break
        case 'service_subcategories':
          redirectUrl = `/user/services/providers${option.payload.service_category ? `/${option.payload.service_category.slug}` : ''}/${slug}`
          break
        case 'services':
          redirectUrl = `/user/services/providers${option.payload.service_category ? `/${option.payload.service_category.slug}` : ''}/${slug}`
          break
        case 'menus':
          if (option.payload.type === 'ORDER') {
            redirectUrl = `/user/store/list/${slug}`
          } else if (option.payload.type === 'SERVICE') {
            redirectUrl = `/user/services/subcategories/list/${slug}`
          } else {
            redirectUrl = `/user/store/list/${slug}`
          }
          break
        default:
          redirectUrl = ''
      }
      if (redirectUrl) {
        this.$router.push({
          path: redirectUrl,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.user-home .input-group {
  width: 50%;
}

.swiper-button-next,
.swiper-button-prev {
  height: 50px !important;
  width: 50px !important;
  background: red;
  border-radius: 50%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
  color: #fff;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  height: 10rem;
  max-height: 10rem;
  padding: 0;
}

.btn-submit-request {
  width: 100px;
  color: #fff !important;
  background: #7367f0 !important;
  border: 0 !important;
  border-top-right-radius: 44px !important;
  border-bottom-right-radius: 44px !important;
}

.input-group .search-criteria-input {
  border-top-left-radius: 44px;
  border-bottom-left-radius: 44px;
  border: 0;
  height: 60px;
}

.ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.ecommerce-application .menu-description {
  text-align: center;
  border-top: 1px solid #ddd;
}

.ecommerce-application .grid-view .ecommerce-card .item-name {
  margin-top: 0;
}

.horizontal-layout.navbar-floating:not(.blank-page) .app-content {
  padding: calc(2.45rem * 2 + 1.3rem) 2rem 0 2rem;
}

.wrap-top-image-section {
  height: 500px;
}

.horizontal-layout.navbar-floating .header-navbar-shadow {
  height: 0;
}

.btn-input-group-search {
  box-shadow: 0px 5px 20px #eaecef;
  background: transparent;
  border-radius: 44px;
  height: 60px;
}

.font-title-home-image {
  font-size: 45px;
}

.icon-width {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ddd;
  margin: 0 25px;
  cursor: pointer;
}

.wrap-category-section {
  background: #f7f8fa;
}

.image-section:hover {
  background: #eef0f2;
}

.image-section {
  height: 145px;
  width: 100%;
  position: relative;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.image-section .section-title-bottom {
  position: absolute;
  bottom: 15px;
  font-size: 16px;
}

.image-section.active {
  background: #fe180c;
  color: #fff;
}

.image-section.active::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 10px;
  border-top-color: #fe180c;
  content: '';
  pointer-events: none;
}

.wrap-home-page-title {
  color: #1e2432;
}

section.grid-view {
  padding: 0 30px;
}

.wrap-auto-suggest-box {
  position: absolute;
  width: 100%;
  top: 60px;
  max-width: calc(100% - 50px);
  left: 25px;
  border: 1px solid #7367f0;
  background: #fff;
  z-index: 99;
  border-radius: 10px;
  margin-top: 2px;
  box-shadow: 0px 5px 20px #eaecef;
  border-color: #ccc;
}

ul.general-search {
  max-height: 250px;
  overflow-y: auto;
  padding-left: 0;
}

ul.general-search li:hover {
  background: #f1f1f1;
}

.wrap-auto-suggest-box .general-search li {
  padding: 2px 10px;
  border: none;
  list-style-type: none;
  cursor: pointer;
}

.menu-width-height {
  width: 90%;
  height: 90%;
}

.active .menu-width-height {
  width: 100%;
  height: 100%;
}

.select-city-options {
  display: inline-block !important;
  width: auto;
  background: transparent;
  margin-left: 10px;
  color: #fff;
}

.select-city-options option {
  color: #000;
}
</style>

<style scoped>
@media only screen and (max-width: 767px) {
  .ecommerce-application.home-user-landing .grid-view {
    grid-template-columns: 1fr !important;
  }

  .ecommerce-application.home-user-landing {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body .home-user-landing .image-section {
    display: none !important;
  }

  body .home-user-landing .image-section.active {
    display: flex !important;
  }

  .btn-input-group-search .input-group-append {
    width: 25%;
  }
}
</style>

<style>
@media only screen and (max-width: 1024px) {
  .responsive-web-user .backdrop-effect {
    backdrop-filter: blur(1px);
    bottom: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .horizontal-layout.navbar-floating:not(.blank-page) .app-content.home-user-landing {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .home-user-landing .wrap-auto-suggest-box {
    max-width: 85% !important;
  }

  .select-city-options {
    width: 100% !important;
    display: block !important;
    margin-top: 10px !important;
    margin-left: 10px !important;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/home.scss';
</style>
