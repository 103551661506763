<template>
  <b-card
    class="ecommerce-card user-offer-cards"
    no-body
    @click="redirectUser"
  >
    <div
      v-if="cardType === 'category' && !product.isMenuCategory"
      class="item-img text-center p-0 no-padding-section relative"
    >
      <b-link
        :to="getRedirectUrl"
        class="w-100 h-100"
      >
        <b-img
          fluid
          class="card-img-top w-100 h-100 fit-image-contains"
          :src="$helpers.imageHelper(product.picture)"
          @error="$helpers.imageError"
        />
      </b-link>
      <div
        v-if="showOffers"
        class="wrap-offer-info w-100 text-left pl-0 text-black wrapper-offer-text"
      >
        <p
          class="mb-0 text-black"
          style="word-break: break-all"
        >
          {{ product.promo_description }}
        </p>
        <!-- <p class="mb-0 font-10 text-black add-code-text">
          {{ $t('ADD CODE') }}
        </p>
        <b-button
          variant="primary"
          class="btn-offer-color user-code-effect"
          :to="{ path: offerRedirect }"
        >
          {{ product.promo_code }}
        </b-button> -->
      </div>
    </div>
    <div
      v-if="cardType === 'category' && product.isMenuCategory"
      class="item-img text-center p-0 no-padding-section relative category-type"
    >
      <b-link class="w-100 h-100">
        <b-img
          fluid
          class="card-img-top w-100 h-100"
          :src="$helpers.imageHelper(product.featured_image)"
          @error="$helpers.imageError"
        />
      </b-link>
      <div
        v-if="showOffers"
        class="category-section-rendered wrap-offer-info w-100 text-left pl-3 text-white"
      >
        <b-button
          v-if="!isThirdPartyRedirect"
          variant="primary"
          class="btn-offer-color normal-redirect"
          :to="{ path: getMenuCategoryRedirect }"
        >
          {{ product.title }}
        </b-button>
        <b-button
          v-if="isThirdPartyRedirect"
          variant="primary"
          class="btn-offer-color third-party-redirect"
          @click="goToThirdParty"
        >
          {{ product.title }}
        </b-button>
      </div>
    </div>
    <div
      v-if="cardType === 'menu'"
      class="item-img text-center p-0 no-padding-section relative menu-type"
    >
      <b-link class="w-100 h-100">
        <b-img
          fluid
          class="card-img-top w-100 h-100"
          :src="$helpers.imageHelper(product.icon)"
          @error="$helpers.imageError"
        />
      </b-link>
      <div
        v-if="showOffers"
        class="wrap-offer-info w-100 text-left pl-3 text-white"
      >
        <b-button
          v-if="!isThirdPartyRedirect && !getMenuCategoryRedirect.startsWith('/app')"
          variant="primary"
          class="btn-offer-color normal-redirect"
          :to="{ path: getMenuCategoryRedirect }"
        >
          {{ product.title }}
        </b-button>
        <b-button
          v-if="isThirdPartyRedirect && !getMenuCategoryRedirect.startsWith('/app')"
          variant="primary"
          class="btn-offer-color third-party-redirect"
          @click="goToThirdParty"
        >
          {{ product.title }}
        </b-button>
        <b-button
          v-if="getMenuCategoryRedirect.startsWith('/app')"
          variant="primary"
          class="btn-offer-color"
          @click="goToLaravelApp(getMenuCategoryRedirect)"
        >
          {{ product.title }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BLink, BImg, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BLink,
    BImg,
    BButton,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    cardType: {
      type: String,
      default: 'category',
    },
    showOffers: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    responsiveSize() {
      return this.$store.getters['app/windowSize'] <= 1024
    },
    getRedirectUrl() {
      let baseUrl = ''
       if (this.product.type === 'STORE') {
        baseUrl = `/user/store/details/${this.product.store.slug}`
      } else if (this.product.type === 'PROVIDER') {
        baseUrl = `/user/services/subcategories/list/${this.product.provider.slug}`
      } else {
        // eslint-disable-next-line no-script-url
        baseUrl = 'javascript: void(0)'
      }
      return baseUrl
    },
    isThirdPartyRedirect() {
      return this.product && this.product.third_party
    },
    thirdPartyRedirectUrl() {
      return this.product ? this.product.third_party_url : this.getMenuCategoryRedirect
    },
    offerRedirect() {
      if (!this.product) return null
      let baseUrl = ''
      if (this.product.type === 'STORE' && this.product.store) baseUrl = `/user/store/details/${this.product.store.slug}`
      if (this.product.type === 'PROVIDER' && this.product.provider) baseUrl = `/user/service/provider/${this.product.provider.slug}`
      return baseUrl
    },
    getMenuCategoryRedirect() {
      if (!this.product) return ''
      if (!this.product.service) return ''
      let baseUrl = ''
      if (this.product.service.admin_service === 'TRANSPORT' && this.product.menu_type_id) {
        baseUrl = `/app/user/ride/${this.product.menu_type_id}/transport`
      } else if (this.product.service.admin_service === 'DELIVERY' && this.product.menu_type_id) {
        baseUrl = `/app/user/delivery/${this.product.menu_type_id}/delivery`
      } else if (this.product.service.admin_service === 'ORDER' && this.product.slug) {
        baseUrl = `/user/store/list/${this.product.slug}`
      } else if (this.product.service.admin_service === 'SERVICE' && this.product.slug) {
        baseUrl = `/user/services/subcategories/list/${this.product.slug}`
      } else {
        baseUrl = ''
      }
      return baseUrl
    },
  },
  methods: {
    goToThirdParty() {
      if (!this.isThirdPartyRedirect) {
        this.redirectUser()
      }
      this.$swal({
        title: this.$t('You want to redirect to third party url?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          setTimeout(() => {
            window.open(this.thirdPartyRedirectUrl)
          }, 100)
        }
      })
    },
    goToLaravelApp(gotoApp) {
      if (gotoApp) window.location.href = gotoApp
    },
    redirectUser() {
      if (this.isThirdPartyRedirect) {
        this.goToThirdParty()
        return
      }
      if (this.cardType === 'category' && !this.product.isMenuCategory) {
        if (!this.offerRedirect) {
          this.$router.push({
            path: this.offerRedirect,
          })
        }
      } else if ((this.cardType === 'category' && this.product.isMenuCategory) || this.cardType === 'menu') {
        if (this.getMenuCategoryRedirect && !this.getMenuCategoryRedirect.startsWith('/app')) {
          this.$router.push({
            path: this.getMenuCategoryRedirect,
          })
        } else if (this.getMenuCategoryRedirect.startsWith('/app')) {
          this.goToLaravelApp(this.getMenuCategoryRedirect)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.no-padding-section {
  height: 10rem;
  max-height: 10rem;
}

.wrap-offer-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.wrapper-offer-text {
  display: inline-block;

  p {
    display: inline-block;
    background-color: #7367f0;
    color: #fff;
    padding: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
}

.menu-category-offer-cards .wrap-offer-info {
  top: unset;
  transform: unset;
  bottom: 10px;
  left: 0;
}

.btn-offer-color {
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
}

.wrap-offer-info .btn-offer-color:hover {
  box-shadow: 0 8px 25px -8px #7367f0;
}

@media only screen and (max-width: 767px) {
    .normal-redirect, .third-party-redirect {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
