<template>
  <div class="row">
    <skeleton-box
      v-if="showTopLine"
      class="ml-2 mb-2 mr-2"
      width="98%"
    />
    <div
      v-for="(item, index) of maxBoxes"
      :key="index"
      class="mb-2"
      :class="col"
    >
      <skeleton-box
        width="100%"
        height="200px"
      />
    </div>
  </div>
</template>

<script>
import SkeletonBox from '@/@core/layouts/shared/SkeletonBox.vue'

export default {
  components: {
    SkeletonBox,
  },
  props: {
    showTopLine: {
      type: Boolean,
      default: true,
    },
    col: {
      type: String,
      default: 'col-md-3',
    },
    boxes: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    maxBoxes() {
      return new Array(this.boxes).fill(0)
    },
  },
}
</script>
