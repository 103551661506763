<template>
  <b-spinner
    style="width: 3rem; height: 3rem;"
    label="Large Spinner"
    type="grow"
  />
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
}
</script>
